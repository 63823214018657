// // This is the scss entry file
import "../styles/index.scss";

import {MDCTopAppBar} from '@material/top-app-bar';
import {MDCRipple} from '@material/ripple';
import 'ol/ol.css';

// Instantiation

const topAppBarElement = document.querySelector('.mdc-top-app-bar');
new MDCTopAppBar(topAppBarElement);

const selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action, .mdc-text-field';
[].map.call(document.querySelectorAll(selector), function(el) {
  return new MDCRipple(el);
});
